import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useLocation, useNavigate} from 'react-router-dom'
import {Props} from 'react-select'
import {
  apiBaseURL,
  checkEmpty,
  checkUserType,
  dateFormat,
  fetchAsyncDropdownNew,
  isAdmin,
  isEmpty,
  numberFormat,
  numberFormatWithCode,
  retrieveFilter,
  saveFilter,
} from '../../../../_eaFruitsDms/helpers'
import EATitle from '../../../../_eaFruitsDms/layout/components/title/title'
import {AsyncPaginate} from 'react-select-async-paginate'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import tenantConfiguration from '../../../../TenantVariables'
import {getRequest} from '../../../../_eaFruitsDms/https/apiCall'
import moment from 'moment'
import IsLoader from '../../../../_eaFruitsDms/layout/components/loader/loader'
import NoRecords from '../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomPaginate from '../../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import ResetButton from '../../../../_eaFruitsDms/layout/components/button/resetButton'
import {filters} from '../../../../_eaFruitsDms/utils/FilterConstants'
import {EaStatisticsWidget} from '../../../../_eaFruitsDms/partials/widgets'
import FetchingRecords from '../../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import {paymentsPayLaterToday, paymentsStatus} from './components/paymentConstant'
import CustomSearchBar from '../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import useDebounce from '../../../../_eaFruitsDms/helpers/components/useDebounce'

const PayLaterTodayReport: React.FC<Props> = ({className}) => {
  const warehouseName: any = localStorage.getItem('warehouse_name')

  const intl = useIntl()
  const userType: any = localStorage.getItem('user_type') || ''
  const currentWarehouseId = !['sa', 'am', 'ts'].includes(userType.toLowerCase())
    ? localStorage.getItem('w_id')
    : ''

  const location = useLocation()

  const headerTitle = {
    title: 'Pay Later Today Report',
    subTitle: 'Pay Later Today Report',
  }

  let payLaterTodayFiltered: any = retrieveFilter(filters.sa_pay_later_today_report)
  let todayDate = dateFormat(moment())

  const initialState = {
    sr: payLaterTodayFiltered?.sr || {label: 'All Sales Reps', value: ''},
    customer: payLaterTodayFiltered?.customer || {label: 'All Customers', value: ''},
    warehouseId:
      payLaterTodayFiltered?.warehouseId ||
      (!['sa', 'ts', 'wm', 'am'].includes(userType.toLowerCase())
        ? {label: warehouseName, value: currentWarehouseId}
        : {label: 'All Warehouses', value: ''}),
    paymentMethodType: payLaterTodayFiltered?.paymentMethodType || {
      label: 'All Payments',
      value: '',
    },
    status: payLaterTodayFiltered?.status || {label: 'All Status', value: ''},
    startDate: payLaterTodayFiltered?.startDate || todayDate,
    endDate: payLaterTodayFiltered?.endDate || todayDate,
    search: payLaterTodayFiltered?.search || '',
    selected: payLaterTodayFiltered?.selected || '',
    currentOffset: payLaterTodayFiltered?.currentOffset || '',
    buttonDisable: true,
  }

  const initialDateFilter = {
    startDate: payLaterTodayFiltered?.startDate || todayDate,
    endDate: payLaterTodayFiltered?.endDate || todayDate,
  }

  const [metaCounts, setMetaCounts] = useState({
    total_pay_later_transactions: 0,
    converted_to_credit_issue: 0,
    total_pay_later_amount: 0,
    converted_credit_issue_amount: 0,
  })

  const [searchSelect, setSearchSelect] = useState<string | undefined>('')
  const [limit, setLimit] = useState(tenantConfiguration.limit)
  const [customer, setCustomer] = useState<any>(
    payLaterTodayFiltered?.customer || {label: 'All Customers', value: ''}
  )
  const [customerOffSet, setCustomerOffSet] = useState<any>('')
  const [sr, setSr] = useState<any>(
    payLaterTodayFiltered?.sr || {label: 'All Sales Reps', value: ''}
  )
  const [status, setStatus] = useState<any>(
    payLaterTodayFiltered?.status || {label: 'Status', value: ''}
  )
  const [paymentMethodType, setPaymentMethodType] = useState<any>(
    payLaterTodayFiltered?.paymentMethodType || {label: 'All Payments', value: ''}
  )
  const [srOffSet, setSrOffSet] = useState<any>('')
  const [salesRepsOption, setSalesRepsOption] = useState<any>([])
  const [search, setSearch] = useState<string>(payLaterTodayFiltered?.search || '')
  const [warehouseId, setWarehouseId] = useState<any>(
    payLaterTodayFiltered?.warehouseId ||
      (!['sa', 'ts', 'wm', 'am'].includes(userType.toLowerCase())
        ? {label: warehouseName, value: currentWarehouseId}
        : {label: 'All Warehouses', value: ''})
  )
  const [warehouses, setWarehouses] = useState<any>([])
  const [warehouseOffSet, setWarehouseOffSet] = useState<any>('')
  const [payLater, setPayLater] = useState<any>([])
  const [displayLoader, setDisplayLoader] = useState<boolean>(false)
  const [selected, setSelected] = useState<number>(payLaterTodayFiltered?.selected || 0)
  const [count, setCount] = useState<string>('1')
  const [currentOffset, setCurrentOffset] = useState<number>(
    payLaterTodayFiltered?.currentOffset || '0'
  )
  const [filterStates, setFilterStates] = useState<any>(initialState)
  const [buttonDisable, setButtonDisable] = useState<boolean>(
    payLaterTodayFiltered?.buttonDisable || false
  )
  const [invoiceDate, setInvoiceDate] = useState<any>(initialDateFilter)
  const [customerReset, setCustomerReset] = useState<any>(moment())
  const [srReset, setSrReset] = useState<any>(new Date())
  const [customers, setCustomers] = useState<any>([])

  const debounceSearch = useDebounce(search, 500)

  useEffect(() => {
    setCustomerOffSet('')
    setWarehouseOffSet('')
  }, [searchSelect])

  useEffect(() => {
    fetchPayLaterReport().then()

    let updatedState: any = {...filterStates}
    updatedState['selected'] = selected
    updatedState['currentOffset'] = currentOffset
    updatedState['buttonDisable'] = buttonDisable
    setFilterStates(updatedState)

    saveFilter(filters.sa_pay_later_today_report, filterStates)

    if (
      warehouseId.value === '' &&
      sr.value === '' &&
      customer.value === '' &&
      status === '' &&
      debounceSearch === '' &&
      invoiceDate.endDate.apiDate === todayDate.apiDate &&
      invoiceDate.startDate.apiDate === todayDate.apiDate
    ) {
      setButtonDisable(false)
    }
  }, [
    customer,
    sr,
    currentOffset,
    status,
    invoiceDate,
    warehouseId,
    paymentMethodType,
    debounceSearch,
  ])

  const fetchPayLaterReport = async () => {
    setDisplayLoader(true)

    let filterWarehouseId: any = warehouseId.value

    if (checkUserType(['am']) && location.pathname === '/reports/pay-later-today-report') {
      filterWarehouseId = ''
    }

    const response = await getRequest(
      `${apiBaseURL()}reports/pay-later-today?limit=${limit}&offset=${currentOffset}&search=${debounceSearch}&customer_id=${
        customer.value
      }&sr_id=${sr.value}&status=${status.value}&pay_later_payments=${
        paymentMethodType.value
      }&date_duration_after=${invoiceDate.startDate.apiDate}&date_duration_before=${
        invoiceDate.endDate.apiDate
      }&warehouse_id=${checkEmpty(filterWarehouseId, '')}`,
      true
    )

    if (response.results) {
      if (!isEmpty(response.meta)) {
        setMetaCounts({
          total_pay_later_transactions: isEmpty(response.meta.total_pay_later_transactions)
            ? 0
            : response.meta.total_pay_later_transactions,
          converted_to_credit_issue: isEmpty(response.meta.converted_to_credit_issue)
            ? 0
            : response.meta.converted_to_credit_issue,
          total_pay_later_amount: isEmpty(response.meta.total_pay_later_amount)
            ? 0
            : response.meta.total_pay_later_amount,
          converted_credit_issue_amount: isEmpty(response.meta.converted_credit_issue_amount)
            ? 0
            : response.meta.converted_credit_issue_amount,
        })
      }
      setPayLater(response.results)
      setCount(response.count)
    }

    setDisplayLoader(false)
  }

  const fetchWarehouses = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }

    let options: any = []
    const warehouseResponse = await getRequest(
      `${apiBaseURL()}warehouse/list?ordering=name&search=${search}&limit=${limit}&offset=${warehouseOffSet}&parent_warehouse__id=${currentWarehouseId}&is_parent=True`,
      true
    )

    let hasMore: boolean = false

    if (warehouseResponse.next !== null) {
      const queryParams = new URLSearchParams(warehouseResponse.next)
      let newOffset: any = queryParams.get('offset')
      setWarehouseOffSet(newOffset)
      hasMore = true
    }

    if (warehouses.length == 0) {
      options.push({
        label: 'All Warehouses',
        value: '',
      })
    }

    if (warehouseResponse.results.length > 0) {
      warehouseResponse.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }

    if (warehouses.length > 0) {
      setWarehouses(warehouses.concat(options))
    } else {
      setWarehouses(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchCustomers = async (search?: string) => {
    if (search !== '') {
      setSearchSelect(search)
    }

    let wId: any = localStorage.getItem('w_id') || ''

    if (typeof wId === 'object') {
      wId = wId.value
    }

    if (!isEmpty(warehouseId)) {
      wId = warehouseId
      if (typeof warehouseId === 'object') {
        wId = warehouseId.value
      }
    }

    const customerResponse = await getRequest(
      `${apiBaseURL()}customers/drop-down?ordering=business_name&search=${searchSelect}&limit=${limit}&offset=${customerOffSet}&warehouse_id=${
        !isEmpty(wId) ? wId : ''
      }`,
      true
    )

    let options: any = []

    let hasMore: boolean = false

    if (customerResponse.results) {
      if (customers.length === 0) {
        options.push({
          label: 'All Customers',
          value: '',
        })
      }

      if (customerResponse.next !== null) {
        const queryParams = new URLSearchParams(customerResponse.next)
        let newOffset: any = queryParams.get('offset')
        setCustomerOffSet(newOffset)
        hasMore = true
      }

      if (customerResponse.results.length > 0) {
        customerResponse.results.map((option: any) => {
          options.push({
            label: option.business_name + ', ' + option.street,
            value: option.id,
          })
        })
      }
    }

    if (customers.length > 0) {
      setCustomers(customers.concat(options))
    } else {
      setCustomers(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchSrs = async (search?: string) => {
    let wId = ['sa', 'wm'].includes(userType.toLowerCase())
      ? ''
      : localStorage.getItem('w_id') || ''

    if (!isEmpty(warehouseId)) {
      wId = typeof warehouseId === 'object' ? warehouseId.value : warehouseId
    }
    if (!isEmpty(warehouseId.value)) {
      wId = warehouseId.value
    }

    return await fetchAsyncDropdownNew(
      srOffSet,
      setSrOffSet,
      salesRepsOption,
      setSalesRepsOption,
      `auth/users/team/list?ordering=first_name&warehouse__id=${isEmpty(wId) ? '' : wId}&type=sr`,
      search,
      setSearchSelect,
      'first_name',
      'last_name',
      true,
      'All Sales Reps'
    )
  }

  const fetchPayLaterToday = () => {
    return {
      options: paymentsPayLaterToday,
      hasMore: false,
    }
  }

  const fetchPaymentStatus = () => {
    return {
      options: paymentsStatus,
      hasMore: false,
    }
  }

  const handleFilterChange = (e: any, name?: string) => {
    const inputName = e.target ? e.target.name : name
    const inputValue = e.target ? e.target.value : e.value

    setSearchSelect('')
    setCurrentOffset(0)
    setSelected(0)
    setButtonDisable(true)
    const currentMomentObj: any = moment()
    switch (inputName) {
      case 'warehouse':
        localStorage.setItem('selected-wid', inputValue)
        setFilterStates({...filterStates, ['warehouseId']: e})
        setWarehouseId(e)
        setCustomerOffSet('')
        setCustomers([])
        setCustomerReset(currentMomentObj)
        setSrOffSet('')
        setSrReset(new Date())
        setSalesRepsOption([])
        break
      case 'customer':
        setCustomer(e)
        setFilterStates({...filterStates, ['customer']: e})
        break
      case 'sr':
        setSr(e)
        setFilterStates({...filterStates, ['sr']: e})
        break
      case 'paymentMethodType':
        setPaymentMethodType(e)
        setFilterStates({...filterStates, ['paymentMethodType']: e})
        break
      case 'status':
        setStatus(e)
        setFilterStates({...filterStates, ['status']: e})
        break
    }
  }

  const handleChangeReset = () => {
    setSearchSelect('')
    setSearch('')
    setSr({label: 'All Sales Reps', value: ''})
    setCustomer({label: 'All Customers', value: ''})
    setWarehouseId({label: 'All Warehouses', value: ''})
    setStatus({label: 'Status', value: ''})
    setPaymentMethodType({label: 'All Payments', value: ''})
    setCurrentOffset(0)
    setSelected(0)

    let updatedDateState: any = {...invoiceDate}
    updatedDateState['startDate'] = todayDate
    updatedDateState['endDate'] = todayDate
    setInvoiceDate(updatedDateState)

    let updatedState: any = {...filterStates}
    updatedState['sr'] = ''
    updatedState['customer'] = ''
    updatedState['warehouseId'] = ''
    updatedState['paymentMethodType'] = ''
    updatedState['status'] = ''
    updatedState['search'] = ''
    updatedState['currentOffset'] = ''
    updatedState['selected'] = ''
    updatedState['startDate'] = ''
    updatedState['endDate'] = ''
    setFilterStates(updatedState)
    setButtonDisable(false)
  }
  const CustomDatePickerComponent = () => {
    return (
      <>
        <DateRangePicker
          onCallback={(start, end, label) => {
            setButtonDisable(true)
            const callBakeDate = {
              startDate: dateFormat(start._d),
              endDate: dateFormat(end._d),
            }
            localStorage.setItem('start-date', callBakeDate.startDate.apiDate)
            localStorage.setItem('end-date', callBakeDate.endDate.apiDate)
            let updatedState: any = {...filterStates}
            updatedState['startDate'] = callBakeDate.startDate
            updatedState['endDate'] = callBakeDate.endDate
            setFilterStates(updatedState)
            setInvoiceDate(callBakeDate)
          }}
          initialSettings={{
            startDate: moment(invoiceDate.startDate.apiDate).toDate(),
            endDate: moment(invoiceDate.endDate.apiDate).toDate(),
            autoUpdateInput: true,
            alwaysShowCalendars: true,
            cancelClass: 'btn-secondary',
            drops: 'auto',
            locale: {
              format: 'DD MMM YYYY',
            },
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
              'Last 7 Days': [moment().subtract(6, 'days'), moment()],
              'Last 30 Days': [moment().subtract(29, 'days'), moment()],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
              'Last Month': [
                moment().subtract(1, 'month').startOf('month'),
                moment().subtract(1, 'month').endOf('month'),
              ],
            },
          }}
        >
          <input
            type='text'
            className='form-control calendar my-1 me-3 w-auto'
            defaultValue={invoiceDate}
            disabled={displayLoader}
          />
        </DateRangePicker>
      </>
    )
  }
  return (
    <>
      <EATitle title='Pay Later Today Report' />
      <PageTitle breadcrumbs={[headerTitle]}>
        {intl.formatMessage({
          id: 'Pay Later Today Report',
        })}
      </PageTitle>

      <div className={`card ${className}`}>
        {displayLoader && <IsLoader />}
        {/* begin::Header */}
        <div className='card-header border-0 py-2 px-7 align-items-center'>
          <div className='my-2 mb-3'>
            <CustomSearchBar
              onChange={(e: any) => {
                setSearch(e.target.value)
                setCurrentOffset(0)
                setSelected(0)

                let updatedState: any = {...filterStates}
                updatedState['search'] = e.target.value
                setFilterStates(updatedState)
              }}
              isDisable={displayLoader}
            />
          </div>

          <div className='ms-auto'>
            <div className='card-toolbar d-flex my-0'>
              <div className=''>
                <CustomDatePickerComponent />
              </div>

              {checkUserType(['sa', 'wm', 'ts']) &&
                ['/sales-invoices', '/reports/pay-later-today-report'].includes(
                  location.pathname
                ) && (
                  <AsyncPaginate
                    loadOptions={fetchWarehouses}
                    isDisabled={displayLoader}
                    isSearchable
                    className='react-select-container my-1 me-3 w-160px'
                    classNamePrefix='react-select'
                    placeholder='All Warehouses'
                    value={warehouseId}
                    onChange={(e: any) => handleFilterChange(e, 'warehouse')}
                    onBlur={() => setSearchSelect('')}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#0c78a4',
                      },
                    })}
                  />
                )}
              <AsyncPaginate
                key={srReset}
                loadOptions={fetchSrs}
                isDisabled={displayLoader}
                className='react-select-container my-1 me-3 w-140px'
                classNamePrefix='react-select'
                placeholder='All SRs'
                value={sr}
                onBlur={() => setSearchSelect('')}
                onChange={(e: any) => handleFilterChange(e, 'sr')}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />

              <AsyncPaginate
                key={customerReset}
                loadOptions={fetchCustomers}
                isDisabled={displayLoader}
                className='react-select-container my-1 me-3 w-200px'
                classNamePrefix='react-select'
                placeholder='All Customers'
                value={customer}
                onBlur={() => setSearchSelect('')}
                onChange={(e: any) => handleFilterChange(e, 'customer')}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />

              <AsyncPaginate
                loadOptions={fetchPayLaterToday}
                isDisabled={displayLoader}
                onBlur={() => setSearchSelect('')}
                className='react-select-container my-1 me-3 w-175px'
                classNamePrefix='react-select'
                onChange={(e: any) => handleFilterChange(e, 'paymentMethodType')}
                placeholder='Paid Later Today'
                value={paymentMethodType}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />

              <AsyncPaginate
                loadOptions={fetchPaymentStatus}
                isDisabled={displayLoader}
                className='react-select-container my-1 me-3 w-175px'
                classNamePrefix='react-select'
                onChange={(e: any) => handleFilterChange(e, 'status')}
                onBlur={() => setSearchSelect('')}
                placeholder='Status'
                value={status}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
              <ResetButton onClick={handleChangeReset} isDisable={buttonDisable} />
            </div>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body pt-0 pb-3'>
          {checkUserType(['sa', 'wm', 'oo', 'am', 'ts']) &&
            location.pathname === '/reports/pay-later-today-report' && (
              <div className='row g-3 g-xl-5'>
                <div className='col-sm-6 col-md-6 col-lg col-xl'>
                  <EaStatisticsWidget
                    className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
                    color='success'
                    title={numberFormat(metaCounts.total_pay_later_transactions).displayFormat}
                    description='Total Pay Later Transactions'
                  />
                </div>
                <div className='col-sm-6 col-md-6 col-lg col-xl'>
                  <EaStatisticsWidget
                    className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
                    color='info'
                    title={numberFormat(metaCounts.converted_to_credit_issue).displayFormat}
                    description='Converted to Credit Issue'
                  />
                </div>
                <div className='col-sm-6 col-md-6 col-lg col-xl'>
                  <EaStatisticsWidget
                    className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
                    color='dark'
                    title={numberFormatWithCode(metaCounts.total_pay_later_amount).displayFormat}
                    description='Total Pay Later Issue Amt.'
                  />
                </div>
                <div className='col-sm-6 col-md-6 col-lg col-xl'>
                  <EaStatisticsWidget
                    className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
                    color='warning'
                    title={
                      numberFormatWithCode(metaCounts.converted_credit_issue_amount).displayFormat
                    }
                    description='Converted to Credit Issue Amt.'
                  />
                </div>
              </div>
            )}
          <div className='table-responsive'>
            {/* begin::Table */}
            {displayLoader ? (
              <FetchingRecords />
            ) : payLater.length === 0 ? (
              <NoRecords />
            ) : (
              <table className='table table-row-bordered table-hover table-row-gray-100 align-middle gs-0 gy-2 mt-3'>
                {/* begin::Table head */}
                <thead>
                  <tr className='text-muted fw-500'>
                    <th className='min-w-70px'>Order No.</th>
                    <th className='min-w-120px'>Date</th>
                    <th className='min-w-160px'>Customer Name</th>
                    <th className='min-w-120px'>SR Name</th>
                    <th className='min-w-120px'>Receivable Amt.</th>
                    <th className='min-w-120px'>Received Amt.</th>
                    <th className='min-w-120px'>Credit Issue Amt</th>
                    <th className='min-w-100px'>Status</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {payLater.map((payment: any) => {
                    return (
                      <tr>
                        <td>{payment.order_no ? '#' + payment.order_no : '-'}</td>
                        <td>{dateFormat(payment.date).displayDate}</td>
                        <td>{isEmpty(payment.customer_name) ? '-' : payment.customer_name}</td>
                        <td>{isEmpty(payment.sr_name) ? '-' : payment.sr_name}</td>
                        <td>{numberFormatWithCode(payment.receivable_amount).displayFormat}</td>
                        <td>{numberFormatWithCode(payment.received_amount).displayFormat}</td>
                        <td>{numberFormatWithCode(payment.credit_issue_amount).displayFormat}</td>
                        <td>
                          {payment.status === 'open' ? (
                            <span className='badge badge-light-success text-dark'>Open</span>
                          ) : payment.status === 'closed' ? (
                            <span className='badge badge-light-dark text-dark'>Closed</span>
                          ) : payment.status === 'overdue' ? (
                            <span className='badge badge-light-danger text-dark'>Overdue</span>
                          ) : (
                            ''
                          )}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
                {/* end::Table body */}
              </table>
            )}
            {/* end::Table */}
          </div>
        </div>
      </div>

      <CustomPaginate
        data={payLater}
        selected={selected}
        setSelected={setSelected}
        setCurrentOffset={setCurrentOffset}
        totalRecords={count}
        limit={limit}
        isDisable={displayLoader}
        saveFilterName={filters.sa_pay_later_today_report}
        filterStates={filterStates}
      />
    </>
  )
}

export default PayLaterTodayReport
