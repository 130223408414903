import React, {useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {
  apiBaseURL,
  dateFormat,
  dateTimeFormat,
  isAdmin,
  isEmpty,
  KTSVG,
  measurementLabel,
  numberFormat,
  numberFormatWithCode,
  numberToWords,
  salesInvoicePaymentInfo,
} from '../../../../_eaFruitsDms/helpers'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import {useLocation, useNavigate} from 'react-router-dom'
import {blobRequest, getRequest} from '../../../../_eaFruitsDms/https/apiCall'
import tenantConfiguration from '../../../../TenantVariables'
import NoRecords from '../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import {paymentStatus} from '../invoiceConstant'
import FetchingRecords from '../../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'

type Props = {
  className?: string
}
const SalesInvoiceDetail: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const location = useLocation()

  const fromReport = localStorage.getItem('from-report')

  const currencyCode: any = tenantConfiguration.currencyCode
  const paymentRef: any = useRef(null)
  const {state}: any = location

  const [invoiceDetail, setInvoiceDetail] = useState<any>(null)
  const [displayLoader, setDisplayLoader] = useState<boolean>(false)
  const [invoiceNumber, setInvoiceNumber] = useState<any>('-')
  const [downloadButtonDisabled, setDownloadButtonDisabled] = useState<boolean>(false)

  const HeaderTitle = {
    title: 'Sales Invoices',
    subTitle: invoiceNumber,
  }

  useEffect(() => {
    if (state !== null) {
      fetchInvoiceDetails().then()
    }
  }, [])

  const fetchInvoiceDetails = async () => {
    setDisplayLoader(true)
    //TODO: Prasant Sastri ask to change API from sales-invoices to sales-orders on 30-05-2023
    const response: any = await getRequest(
      `${apiBaseURL()}payment/sales-orders/${state.invoiceId}`,
      true
    )

    if (response.id) {
      if (response) {
        response.pre_order_sku &&
          response.pre_order_sku.map((sku: any, index: number) => {
            const product: any =
              response.delivery_product_order &&
              response.delivery_product_order.find(
                (product_sku: any) => product_sku.product_sku.id === sku.product_sku_id.id
              )

            response.pre_order_sku[index].discounted_price = 0

            if (!isEmpty(product)) {
              if (
                !['0', 0].includes(product.discount_price_per_sku) &&
                !isEmpty(product.delivery_date_discount_type)
              ) {
                if (product.delivery_date_discount_price < product.order_date_price) {
                  response.pre_order_sku[index].product_price = product.order_date_price
                  response.pre_order_sku[index].discounted_price =
                    product.delivery_date_discount_price
                } else if (product.delivery_date_discount_price < product.delivery_date_price) {
                  response.pre_order_sku[index].product_price = product.delivery_date_price
                  response.pre_order_sku[index].discounted_price =
                    product.delivery_date_discount_price
                }
              }

              response.pre_order_sku[index].total_price = product.final_total_price
            }

            return sku
          })

        setInvoiceDetail(response)
      }

      setInvoiceNumber(response.invoice_no ? response.invoice_no : '-')
    }

    setDisplayLoader(false)
  }

  const downloadPdf = async () => {
    setDownloadButtonDisabled(true)
    await blobRequest(
      `${apiBaseURL()}payment/sales-invoices-pdf/${state.invoiceId}`,
      true,
      `Sales Invoice #${invoiceDetail.order_no}`,
      setDownloadButtonDisabled
    )
  }

  return (
    <>
      <PageTitle
        breadcrumbs={[HeaderTitle]}
        backNavigate={
          isAdmin()
            ? fromReport === 'true'
              ? '/reports/sales-invoice-report'
              : '/warehouse/DC/singlewarehousename/sales'
            : '/sales-invoices'
        }
      >
        {HeaderTitle.subTitle}
      </PageTitle>
      {displayLoader ? (
        <FetchingRecords />
      ) : (
        <>
          <div className='card mb-4 mb-xl-5'>
            <div className='card-body pt-5 pb-0'>
              <div className='d-flex flex-wrap flex-sm-nowrap'>
                <div className='flex-grow-1'>
                  <div className='d-flex justify-content-between align-items-start flex-wrap'>
                    <div className='d-flex flex-column mb-2 mb-sm-0'>
                      <div className='mb-1'>
                        <span className='text-dark font-20 fw-bold me-1'>{invoiceNumber}</span>
                      </div>
                      <div className='d-flex flex-wrap mb-2 pe-2 font-14 text-muted'>
                        <span className='d-flex align-items-center me-6 mb-2'>
                          <KTSVG path='/media/icons/duotune/general/gen014.svg' className='me-1' />
                          {invoiceDetail &&
                            invoiceDetail.payment.length > 0 &&
                            !['', null].includes(invoiceDetail.payment[0].created_at) &&
                            dateTimeFormat(invoiceDetail.payment[0].created_at)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <button
                    className='btn btn-primary'
                    onClick={downloadPdf}
                    disabled={downloadButtonDisabled}
                  >
                    {downloadButtonDisabled ? 'Downloading...' : 'Download Invoice'}
                  </button>
                </div>
              </div>
              {/*<div className='d-flex overflow-auto h-40px'>*/}
              {/*  <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fw-500 flex-nowrap'>*/}
              {/*    <li className='nav-item'>*/}
              {/*      <Link*/}
              {/*        className={*/}
              {/*          `nav-link text-active-primary me-0 ` +*/}
              {/*          (location.pathname === '/sales-invoices/sales-invoice-detail' && 'active')*/}
              {/*        }*/}
              {/*        to='/sales-invoices/sales-invoice-detail'*/}
              {/*      >*/}
              {/*        Sales Invoice Detail*/}
              {/*      </Link>*/}
              {/*    </li>*/}
              {/*    <li className='nav-item'>*/}
              {/*      <Link*/}
              {/*        className={*/}
              {/*          `nav-link text-active-primary me-0 ` +*/}
              {/*          (location.pathname === '/sales-invoices/order-feedback' && 'active')*/}
              {/*        }*/}
              {/*        to='/sales-invoices/order-feedback'*/}
              {/*      >*/}
              {/*        Order Feedback*/}
              {/*      </Link>*/}
              {/*    </li>*/}

              {/*  </ul>*/}
              {/*</div>*/}
            </div>
          </div>
          <div className='card mb-5 mb-xl-5'>
            <div className='card-header border-0 py-2 px-7 align-items-center'>
              <div className='card-title my-0'>
                <span className='fw-bold font-17 mb-1'>Invoice Details</span>
              </div>
            </div>
            <div className='card-body pt-2 mt-1 pb-5'>
              <div className='row'>
                <div className='col-sm-6 col-xl-3 mb-6'>
                  <label className='font-14 text-muted mb-2'>Order No.</label>
                  <div>
                    <span className='font-14 text-dark'>
                      {invoiceDetail && '#' + invoiceDetail.order_no}
                    </span>
                  </div>
                </div>
                <div className='col-sm-6 col-xl-3 mb-6'>
                  <label className='font-14 text-muted mb-2'>Delivery Date & Time</label>
                  <div>
                    <span className='font-14 text-dark'>
                      {invoiceDetail && dateTimeFormat(invoiceDetail.delivery_date_time)}
                    </span>
                  </div>
                </div>
                <div className='col-sm-6 col-xl-3 mb-6'>
                  <label className='font-14 text-muted mb-2'>SR Name</label>
                  <div>
                    <span className='font-14 text-dark'>
                      {invoiceDetail && !isEmpty(invoiceDetail.sr_name)
                        ? `${invoiceDetail.sr_name} (SR)`
                        : '-'}
                    </span>
                  </div>
                </div>
                <div className='col-sm-6 col-xl-3 mb-6'>
                  <label className='font-14 text-muted mb-2'>EFD Receipt Number</label>
                  <div>
                    <span className='font-14 text-dark'>
                      {invoiceDetail && (
                        <>
                          {isEmpty(invoiceDetail.tra_verification_code) ? (
                            '-'
                          ) : (
                            <>
                              <div>
                                {' '}
                                {invoiceDetail.tra_verification_code}
                                {!isEmpty(invoiceDetail.tra_verification_url) && (
                                  <>
                                    <a
                                      href={invoiceDetail.tra_verification_url}
                                      target='_blank'
                                      rel='noreferrer'
                                    >
                                      <span className='ms-2 cursor-pointer'>
                                        <i className='fa fa-info-circle' aria-hidden='true'></i>
                                      </span>
                                    </a>
                                  </>
                                )}
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-12 col-xl-3 mb-6'>
                  <label className='font-14 text-muted mb-2'>Customer Name</label>
                  <div>
                    <span className='font-14 text-dark'>
                      {!invoiceDetail || isEmpty(invoiceDetail.customer_name)
                        ? '-'
                        : `${invoiceDetail.customer_name} ${
                            !isEmpty(invoiceDetail.customer_street) &&
                            '(' + invoiceDetail.customer_street + ')'
                          }`}
                    </span>
                  </div>
                </div>
                <div className='col-sm-6 col-xl-3 mb-6'>
                  <label className='font-14 text-muted mb-2'>Customer Type</label>
                  <div>
                    <span className='font-14 text-dark text-capitalize'>
                      {invoiceDetail && !isEmpty(invoiceDetail.customer_type)
                        ? invoiceDetail.customer_type
                        : '-'}
                    </span>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-12 col-xl-3 mb-6'>
                  <label className='font-14 text-muted mb-2'>Invoice Amt.</label>
                  <div>
                    <span className='font-14 text-dark'>
                      {invoiceDetail &&
                        numberFormatWithCode(invoiceDetail.total_payable).displayFormat}
                    </span>
                  </div>
                </div>
                <div className='col-sm-12 col-xl-3 mb-6'>
                  <label className='font-14 text-muted mb-2'>Received Amt.</label>
                  <div>
                    <span className='font-14 text-dark'>
                      {invoiceDetail &&
                        numberFormatWithCode(invoiceDetail.total_received_amount).displayFormat}
                    </span>
                  </div>
                </div>
                <div className='col-sm-12 col-xl-3 mb-6'>
                  <label className='font-14 text-muted mb-2'>Due Amt.</label>
                  <div>
                    <span className='font-14 text-dark'>
                      {invoiceDetail &&
                        numberFormatWithCode(invoiceDetail.total_outstanding_amount).displayFormat}
                    </span>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-6 col-xl-3 mb-6'>
                  <label className='font-14 text-muted mb-2'>Payment By</label>
                  <div>
                    <span className='font-14 text-dark text-capitalize'>
                      {invoiceDetail && invoiceDetail.is_pay_via_credit_request
                        ? 'Credit Request'
                        : invoiceDetail && invoiceDetail.payment.length > 0
                        ? invoiceDetail.payment[0].payment_method_type
                        : '-'}
                    </span>
                  </div>
                </div>
                <div className='col-sm-6 col-xl-3 mb-6'>
                  <label className='font-14 text-muted mb-2'>Payment Status</label>
                  <div>
                    <span className='font-14 text-dark text-capitalize'>
                      {invoiceDetail && !isEmpty(invoiceDetail.order_payment_status)
                        ? paymentStatus[invoiceDetail.order_payment_status]
                        : '-'}
                    </span>
                    {invoiceDetail && invoiceDetail.payment.length > 0 && (
                      <span
                        className='ms-2 cursor-pointer'
                        onClick={() => {
                          if (paymentRef.current) {
                            paymentRef.current.scrollIntoView({behavior: 'smooth'})
                          }
                        }}
                      >
                        <KTSVG
                          path='/media/icons/duotune/general/gen045.svg'
                          className='ic text-primary mr-0'
                        />
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-header border-0 py-2 px-7 align-items-center'>
              <div className='card-title my-0'>
                <span className='fw-bold font-17 mb-1'>Items Delivered</span>
              </div>
            </div>
            <div className='card-body pt-2 mt-1 pb-5'>
              <div className='row'>
                <div className='table-responsive'>
                  {/* begin::Table */}
                  {!invoiceDetail || invoiceDetail.pre_order_sku.length === 0 ? (
                    <NoRecords />
                  ) : (
                    <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                      {/* begin::Table head */}
                      <thead>
                        <tr className='text-muted fw-500'>
                          <th className='min-w-50px py-2'>Sr.</th>
                          <th className='min-w-120px py-2'>SKU</th>
                          <th className='min-w-250px py-2'>Rate</th>
                          <th className='min-w-100px py-2'>Qty.</th>
                          <th className='min-w-120px py-2 text-end'>Amount</th>
                        </tr>
                      </thead>
                      {/* end::Table head */}
                      {/* begin::Table body */}
                      <tbody>
                        {invoiceDetail.pre_order_sku.map((product: any, index: number) => {
                          return (
                            <tr>
                              <td>{index + 1}</td>
                              <td>{product.product_name}</td>
                              <td>
                                {product.order_date_price > product.delivery_date_discount_price ? (
                                  <>
                                    <>{currencyCode}</>{' '}
                                    <>
                                      {
                                        numberFormat(product.delivery_date_discount_price, true)
                                          .displayFormat
                                      }{' '}
                                    </>
                                    <>
                                      <span className='text-decoration-line-through'>
                                        {currencyCode}{' '}
                                        {numberFormat(product.order_date_price, true).displayFormat}
                                      </span>
                                      {/*<br />*/}
                                      {/*{currencyCode} {numberFormat(product.order_date_price - product.delivery_date_discount_price, true).displayFormat} Promotional*/}
                                      {/*Discount*/}
                                    </>
                                  </>
                                ) : (
                                  currencyCode +
                                  ' ' +
                                  numberFormat(product.order_date_price, true).displayFormat
                                )}
                              </td>
                              <td>
                                {product.qty}{' '}
                                {measurementLabel(
                                  product.product_sku.fix_size
                                    ? product.product_sku.material
                                    : product.product_sku.variants &&
                                      product.product_sku.variants.approx_weight > 0
                                    ? product.product_sku.material
                                    : product.product_sku.measurement_unit
                                )}
                              </td>
                              <td className='text-end'>
                                {currencyCode}{' '}
                                {
                                  numberFormat(
                                    (product.order_date_price > product.delivery_date_discount_price
                                      ? product.delivery_date_discount_price
                                      : product.order_date_price) * product.qty,
                                    true
                                  ).displayFormat
                                }
                              </td>
                            </tr>
                          )
                        })}
                        <tr>
                          <td colSpan={7}>
                            <table className='table table-borderless table-row-white-100 align-baseline mt-5'>
                              <tbody>
                                <tr>
                                  <td style={{width: '41%'}} className='align-baseline pt-0'>
                                    <table className='table table-borderless table-row-white-100'>
                                      <tr className='border-0'>
                                        <td className='py-1'>Total Quantity:</td>
                                        <td className='text-end py-1'>
                                          {invoiceDetail ? invoiceDetail.pre_order_sku.length : 0}
                                        </td>
                                      </tr>
                                      <tr className='border-0'>
                                        <td className=' py-1'>Outstanding Amount:</td>
                                        <td className='text-end py-1'>
                                          {currencyCode}{' '}
                                          {
                                            numberFormat(
                                              invoiceDetail &&
                                                invoiceDetail.total_outstanding_amount > 0
                                                ? invoiceDetail.total_outstanding_amount
                                                : 0.0,
                                              true
                                            ).displayFormat
                                          }
                                        </td>
                                      </tr>
                                      <tr className='border-0'>
                                        <td className='py-1'>In Words:</td>
                                        <td className='text-end py-1 text-capitalize'>
                                          {invoiceDetail && invoiceDetail.outstanding_amount > 0
                                            ? numberToWords(invoiceDetail.total_outstanding_amount)
                                            : 'Zero'}
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                  <td
                                    style={{width: '4%'}}
                                    className='border-end border-dark pt-0'
                                  ></td>
                                  <td style={{width: '4%'}} className='pt-0'></td>
                                  <td style={{width: '51%'}} className='pt-0'>
                                    <table className='table table-borderless table-row-white-100 pt-0'>
                                      <tr className='border-0'>
                                        <td className='py-1'>Sub-total without Discount:</td>
                                        <td className='text-end py-1'>
                                          {currencyCode}{' '}
                                          {invoiceDetail
                                            ? numberFormat(invoiceDetail.total_amount, true)
                                                .displayFormat
                                            : 0.0}
                                        </td>
                                      </tr>
                                      {/*<tr className='border-0'>*/}
                                      {/*  <td className='py-1'>Returned Items:</td>*/}
                                      {/*  <td className='text-end py-1'>- {currencyCode} 0.00</td>*/}
                                      {/*</tr>*/}
                                      {/*<tr className='border-0'>*/}
                                      {/*  <td className=' py-1'>Credit Note:</td>*/}
                                      {/*  <td className='text-end py-1'>- {currencyCode} 0.00</td>*/}
                                      {/*</tr>*/}
                                      {invoiceDetail &&
                                        invoiceDetail.applied_discount_type === 'promotional' && (
                                          <tr className='border-0'>
                                            <td className='py-1'>Promotional Discount:</td>
                                            <td className='text-end py-1'>
                                              - {currencyCode}{' '}
                                              {invoiceDetail
                                                ? numberFormat(invoiceDetail.discount, true)
                                                    .displayFormat
                                                : 0.0}
                                            </td>
                                          </tr>
                                        )}
                                      {invoiceDetail &&
                                        invoiceDetail.applied_discount_type === 'lowest_price' && (
                                          <tr className='border-0'>
                                            <td className='py-1'>Lowest Price Discount:</td>
                                            <td className='text-end py-1'>
                                              - {currencyCode}{' '}
                                              {invoiceDetail
                                                ? numberFormat(
                                                    invoiceDetail.lowest_price_discount,
                                                    true
                                                  ).displayFormat
                                                : 0.0}
                                            </td>
                                          </tr>
                                        )}
                                      {invoiceDetail &&
                                        invoiceDetail.applied_discount_type === 'additional' && (
                                          <tr className='border-0'>
                                            <td className='py-1'>Additional Discount:</td>
                                            <td className='text-end py-1'>
                                              - {currencyCode}{' '}
                                              {invoiceDetail
                                                ? numberFormat(
                                                    invoiceDetail.total_additional_discount,
                                                    true
                                                  ).displayFormat
                                                : 0.0}
                                            </td>
                                          </tr>
                                        )}
                                      {/*<tr className='border-0'>*/}
                                      {/*  <td className='py-1'>Near Expiry Discount:</td>*/}
                                      {/*  <td className='text-end py-1'>- {currencyCode} {invoiceDetail ? numberFormat(invoiceDetail.discount, true).displayFormat}</ : .000td>*/}
                                      {/*</tr>*/}
                                      {/*<tr className='border-0'>*/}
                                      {/*  <td className='py-1'>Taxes:</td>*/}
                                      {/*  <td className='text-end py-1'>{currencyCode} 0.00</td>*/}
                                      {/*</tr>*/}
                                      <tr className='border-0'>
                                        <td className='py-1'>Total Payable:</td>
                                        <td className='text-end py-1'>
                                          {currencyCode}{' '}
                                          {invoiceDetail
                                            ? numberFormat(invoiceDetail.total_payable, true)
                                                .displayFormat
                                            : 0.0}
                                        </td>
                                      </tr>
                                      <tr className='border-0'>
                                        <td className='py-1'>In Words:</td>
                                        <td className='text-end py-1 text-capitalize'>
                                          {invoiceDetail
                                            ? numberToWords(invoiceDetail.total_payable)
                                            : '-'}
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                  {/* end::Table */}
                </div>
              </div>
            </div>
          </div>
          {invoiceDetail && invoiceDetail.payment.length > 0 && (
            <div className='card mb-5 mb-xl-5' id='payment-detail' ref={paymentRef}>
              <div className='card-header border-0 py-2 px-7 align-items-center'>
                <div className='card-title my-0'>
                  <span className='fw-bold font-17 mb-1'>Payment Summary</span>
                </div>
              </div>
              <div className='card-body pt-2 mt-1 pb-5'>
                {!invoiceDetail || invoiceDetail.payment === 0 ? (
                  <NoRecords />
                ) : (
                  <div className='table-responsive'>
                    {/* begin::Table */}
                    <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                      {/* begin::Table head */}
                      <thead>
                        <tr className='text-muted fw-500'>
                          <th className='min-w-120px py-2'>Date</th>
                          <th className='min-w-120px py-2'>Amount Paid</th>
                          <th className='min-w-120px py-2'>Outstanding Amt.</th>
                          <th className='min-w-120px py-2'>Payment Details</th>
                          <th className='min-w-250px py-2'>Received By</th>
                        </tr>
                      </thead>
                      {/* end::Table head */}
                      {/* begin::Table body */}
                      <tbody>
                        {invoiceDetail.payment.map((payment: any) => {
                          const paymentInfo = salesInvoicePaymentInfo(payment)
                          return (
                            <tr>
                              <td>{dateFormat(payment.created_at).displayDate}</td>
                              <td>
                                {currencyCode} {numberFormat(payment.amount, true).displayFormat}
                              </td>
                              <td>
                                {numberFormatWithCode(payment.outstanding_amount).displayFormat}
                              </td>
                              <td>
                                <div dangerouslySetInnerHTML={{__html: paymentInfo.payment_mode}} />
                                {paymentInfo.payment_mode === 'cash' && paymentInfo.ref_no}
                              </td>
                              <td>
                                {payment.received_by === null
                                  ? '-'
                                  : payment.received_by.first_name +
                                    ' ' +
                                    payment.received_by.last_name +
                                    '(' +
                                    payment.received_by.type.toUpperCase() +
                                    ')'}
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                    {/* end::Table */}
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </>
  )
}

export default SalesInvoiceDetail
