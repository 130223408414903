import clsx from 'clsx'
import $ from 'jquery'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import DatePicker from 'react-date-picker'
import ReactDOM from 'react-dom'
import {useIntl} from 'react-intl'
import {Props} from 'react-select'
import {AsyncPaginate} from 'react-select-async-paginate'
import tenantConfiguration from '../../../TenantVariables'
import {
  apiBaseURL,
  checkUserType,
  dateFormat,
  dateTimeFormat,
  fetchAsyncDropdown,
  fetchStaticAsyncDropdown,
  getLoginWarehouse,
  isEmpty,
  KTSVG,
  numberFormat,
  numberFormatWithCode,
} from '../../../_eaFruitsDms/helpers'
import {WarehouseFilter} from '../../../_eaFruitsDms/helpers/components/WarehouseFilter'
import useDebounce from '../../../_eaFruitsDms/helpers/components/useDebounce'
import {blobXlsRequest, getRequest, patchRequest} from '../../../_eaFruitsDms/https/apiCall'
import {EaAlertSuccess} from '../../../_eaFruitsDms/layout/components/alert/eaAlert'
import FetchingRecords from '../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import EaModal from '../../../_eaFruitsDms/layout/components/modal/eaModal'
import NoRecords from '../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import CustomPaginate from '../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import CustomSearchBar from '../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import EATitle from '../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../_eaFruitsDms/layout/core'
import {EaStatisticsWidget} from '../../../_eaFruitsDms/partials/widgets'
import {achievedStatus, paidStatus} from './target-constants'

const Targets: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const headerTitle = {
    title: 'Targets',
    subTitle: 'Targets',
  }
  const initialTargetDetails = {
    detail: [],
    total: numberFormatWithCode(0).displayFormat,
  }

  const targetReportId = document.getElementById('am-wm-target-export-button')

  const [metaCount, setMetaCount] = useState<any>({
    total_commission_payable: 0,
    total_commission_paid: 0,
    total_commission_payable_of_month: 0,
    total_commission_paid_of_month: 0,
    total_sr_with_target: 0,
    total_sr: 0,
  })
  const [date, setDate] = useState<any>(moment().toDate())
  const [isCalenderOpen, setIsCalenderOpen] = useState<boolean>(false)
  const [currentOffSet, setCurrentOffSet] = useState<number>(0)
  const [limit, setLimit] = useState<number>(tenantConfiguration.limit)
  const [searchByName, setSearchByName] = useState<string>('')
  const [achieved, setAchieved] = useState<any>({label: 'All Target', value: ''})
  const [status, setStatus] = useState<any>({label: 'All Status', value: ''})
  const [selected, setSelected] = useState<number>(0)
  const [count, setCount] = useState<string>('1')
  const [targets, setTargets] = useState<any>([])
  const [targetDetails, setTargetDetails] = useState<any>(initialTargetDetails)
  const [displayLoader, setDisplayLoader] = useState<boolean>(false)
  const [currentTargetId, setCurrentTargetId] = useState<number>(0)
  const [raiseFlagDetails, setFlagDetails] = useState({srName: '', id: ''})
  const [raiseFlagDetailsModal, setFlagDetailsModal] = useState<any>('')
  const [reasonOffset, setReasonOffset] = useState('')
  const [reasonList, setReasonList] = useState([])
  const [reasonSearch, setReasonSearch] = useState('')
  const [reason, setReason] = useState<any>('')
  const [comment, setComment] = useState('')
  const [validation, setValidation] = useState('')
  const [validationComment, setValidationComment] = useState('')
  const [singleDetails, setSingleDetails] = useState<any>({})
  const [isFetching, setFetching] = useState(true)
  const [isFlagged, setFlagged] = useState(false)
  const [buttonHandler, setButtonHandler] = useState('Submit')
  const [alert, setIsAlert] = useState(false)
  const [message, setMessage] = useState('')
  const [targetId, setTargetId] = useState('')
  const [warehouse, setWarehouse] = useState(
    checkUserType(['wm', 'sa']) ? {label: 'All Warehouses', value: ''} : getLoginWarehouse(),
  )
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)

  const debounceSearch = useDebounce(searchByName, 500)

  useEffect(() => {
    fetchTargets().then()
  }, [debounceSearch, date, achieved, status, warehouse, currentOffSet])

  useEffect(() => {
    if (date !== '') {
      setIsCalenderOpen(false)
    }
  }, [date])

  const fetchTargets = async () => {
    setDisplayLoader(true)
    const selectedDate = dateFormat(date, 'MM yyyy', true).displayDate
    const monthYear = selectedDate.split(' ')

    const response = await getRequest(
      `${apiBaseURL()}targets/am/list?limit=${limit}&offset=${currentOffSet}&search=${debounceSearch}&month=${
        monthYear[0]
      }&year=${monthYear[1]}&incentive_payment_status=${status.value}&is_target_achieved=${
        achieved.value
      }&warehouse_id=${warehouse.value}`,
      true
    )

    if (response.results) {
      if (response.meta) {
        setMetaCount({
          total_commission_payable: isEmpty(response.meta.total_commission_payable)
            ? 0
            : response.meta.total_commission_payable,
          total_commission_paid: isEmpty(response.meta.total_commission_paid)
            ? 0
            : response.meta.total_commission_paid,
          total_commission_payable_of_month: isEmpty(
            response.meta.total_commission_payable_of_month
          )
            ? 0
            : response.meta.total_commission_payable_of_month,
          total_commission_paid_of_month: isEmpty(response.meta.total_commission_paid_of_month)
            ? 0
            : response.meta.total_commission_paid_of_month,
          total_sr_with_target: isEmpty(response.meta.total_sr_with_target)
            ? 0
            : response.meta.total_sr_with_target,
          total_sr: isEmpty(response.meta.total_sr) ? 0 : response.meta.total_sr,
        })
      }

      setCount(response.count)
      setTargets(response.results)

      setDisplayLoader(false)
    }
  }

  const handleFilter = (e: any, name?: string) => {
    const inputName = e.target ? e.target.name : name

    setCurrentOffSet(0)
    setSelected(0)

    switch (inputName) {
      case 'search':
        setSearchByName(e.target.value)
        break
      case 'achieved':
        setAchieved(e)
        break
      case 'status':
        setStatus(e)
        break
      case 'warehouse':
        setWarehouse(e)
        break
    }
  }

  const fetchAchieved = async (search: any) => {
    return fetchStaticAsyncDropdown(achievedStatus, search)
  }

  const fetchStatus = async (search: any) => {
    return fetchStaticAsyncDropdown(paidStatus, search)
  }

  const targetPaid = async (targetId: number) => {
    setDisplayLoader(true)
    await patchRequest(
      `${apiBaseURL()}targets/${targetId}/mark-as-paid`,
      {is_comm_paid: true},
      true
    )

    await fetchTargets()
  }

  const fetchReason = async (search: any) => {
    const response = await fetchAsyncDropdown(
      reasonOffset,
      setReasonOffset,
      reasonList,
      setReasonList,
      `master/options-master/?type=target_undisciplinary_raised_reason&ordering=name`,
      search,
      setReasonSearch,
      'option',
      false
    )

    response.options.push({
      label: 'Other',
      value: '',
    })

    return response
  }

  const markAsFlag = async () => {
    let isValid = true

    if (reason.label && reason.label === 'Other') {
      if (comment === '') {
        isValid = false
        setValidationComment('is-invalid')
      }
    }

    if (reason === '') setValidation('is-invalid')

    if (reason && reason.label !== '' && isValid) {
      setButtonHandler('Please Wait')
      const response = await patchRequest(
        `${apiBaseURL()}targets/${raiseFlagDetails.id}/raise-a-flag`,
        {
          is_raised_disciplinary_case: true,
          reason: reason && reason.value && reason.value === '' ? null : reason.value,
          reason_note: comment === '' ? null : comment,
        },
        true
      )

      if (response.status === 200) {
        setIsAlert(true)
        setMessage('Flag Raised Successfully.')
        $('#closeAddEaModalFlag').trigger('click') // modal close
        isClear()
        setTimeout(() => {
          setIsAlert(false)
        }, 2000)

        await fetchTargets()
      }

      setButtonHandler('Submit')
    }
  }

  const isClear = () => {
    setComment('')
    setReason('')
    setValidation('')
    setValidationComment('')
  }

  const fetchSingleDetails = async (id: string) => {
    setTargetId(id)
    setFetching(true)

    const response = await getRequest(`${apiBaseURL()}targets/am/${id}`, true)

    if (response && response.result) {
      setSingleDetails(response.result)
    }
    setFetching(false)
  }

  const isAchieved = (type: string) => {
    switch (type) {
      case 'new_customers':
        return 'Customers'
      case 'successful_calls':
        return 'Calls'
    }
  }

  const handleFlagged = (target: any) => {
    setFlagged(true)
    setFlagDetailsModal({
      reason_note: target.reason_note ? target.reason_note : '-',
      reason_name: target.reason_name ? target.reason_name : '-',
      raised_disciplinary_date: target.raised_disciplinary_date
        ? target.raised_disciplinary_date
        : '-',
    })
  }

  const downloadReport = async (isDetailsPage?: boolean) => {
    if (isDetailsPage) {
      let apiURL: string = ''
      let fileName: string = ''
      const displayDate = dateFormat(date, 'MMMM YYYY', true).displayDate

      setIsButtonDisabled(true)

      apiURL = `${apiBaseURL()}targets/excel/target-incentive-bifurcation/${targetId}`

      fileName = `Incentive Bifurcation of ${
        isEmpty(singleDetails.sr_user_name) ? '' : singleDetails.sr_user_name
      } - ${displayDate} #${dateTimeFormat(moment())}`

      await blobXlsRequest(apiURL, fileName, true, setIsButtonDisabled)
    } else {
      let apiURL: string = ''
      let fileName: string = ''
      const selectedDate = dateFormat(date, 'MM yyyy', true).displayDate
      const displayDate = dateFormat(date, 'MMMM YYYY', true).displayDate
      const monthYear = selectedDate.split(' ')

      setIsButtonDisabled(true)

      apiURL = `${apiBaseURL()}targets/excel/target-report?&month=${monthYear[0]}&year=${
        monthYear[1]
      }&incentive_payment_status=${status.value}&is_target_achieved=${
        achieved.value
      }&warehouse_id=${warehouse.value}`

      fileName = `Target Report - ${displayDate} #${dateTimeFormat(moment())}`

      await blobXlsRequest(apiURL, fileName, true, setIsButtonDisabled)
    }
  }

  const ExportButton = () => {
    return (
      <button
        type='button'
        className='btn btn-secondary btn-sm btn-icon-dark btn-text-dark mb-0 py-1 me-3 d-flex align-items-center'
        onClick={() => downloadReport()}
        disabled={isButtonDisabled}
      >
        <KTSVG path='/media/icons/duotune/files/fil021.svg' className='svg-icon-muted ic' />
        {isButtonDisabled ? 'Please Wait...' : 'Target Report'}
      </button>
    )
  }

  const ExportButtonDetails = () => {
    return (
      <button
        type='button'
        className='btn btn-secondary btn-sm btn-icon-dark btn-text-dark py-1 d-flex align-items-center me-4'
        onClick={() => downloadReport(true)}
        disabled={isButtonDisabled}
      >
        <KTSVG path='/media/icons/duotune/files/fil021.svg' className='svg-icon-muted ic' />
        {isButtonDisabled ? 'Please Wait...' : 'Export'}
      </button>
    )
  }

  return (
    <>
      {checkUserType(['wm', 'am']) &&
        targetReportId &&
        ReactDOM.render(<ExportButton />, document.getElementById('am-wm-target-export-button'))}

      <EATitle title='Targets' />
      <PageTitle breadcrumbs={[headerTitle]}>{intl.formatMessage({id: 'Targets'})}</PageTitle>
      <EaAlertSuccess
        onClick={() => {
          setIsAlert(false)
        }}
        show={alert}
        message={message}
      />
      <div className='row g-3 g-xl-5'>
        <div className='col-sm-6 col-md-6 col-lg col-xl'>
          <EaStatisticsWidget
            className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
            color='warning'
            title={`${numberFormat(metaCount.total_sr_with_target).displayFormat} / ${
              numberFormat(metaCount.total_sr).displayFormat
            }`}
            description='Target Assigned to SRs'
            path='/'
          />
        </div>
        <div className='col-sm-6 col-md-6 col-lg col-xl'>
          <EaStatisticsWidget
            className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
            color='success'
            title={numberFormatWithCode(metaCount.total_commission_payable).displayFormat}
            path='/'
            description='Total Incentive Payable'
          />
        </div>
        <div className='col-sm-6 col-md-6 col-lg col-xl'>
          <EaStatisticsWidget
            className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
            color='info'
            title={numberFormatWithCode(metaCount.total_commission_paid).displayFormat}
            description='Total Incentive Paid'
            path='/'
          />
        </div>
        <div className='col-sm-6 col-md-6 col-lg col-xl'>
          <EaStatisticsWidget
            className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
            color='dark'
            title={numberFormatWithCode(metaCount.total_commission_payable_of_month).displayFormat}
            description='Incentive Payable This Month'
            path='/'
          />
        </div>
        <div className='col-sm-6 col-md-6 col-lg col-xl'>
          <EaStatisticsWidget
            className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
            color='warning'
            title={numberFormatWithCode(metaCount.total_commission_paid_of_month).displayFormat}
            description='Incentive Paid This Month'
            path='/'
          />
        </div>
      </div>
      <div className={`card ${className}`}>
        {/* {displayLoader && <IsLoader />} */}

        <div className='card-header border-0 py-2 px-7 align-items-center'>
          <CustomSearchBar isDisable={displayLoader} onChange={handleFilter} />
          <div className='ms-auto'>
            <div className='card-toolbar d-flex my-0'>
              {checkUserType(['wm', 'sa']) && (
                <WarehouseFilter
                  warehouse={warehouse}
                  handleFilterChange={(e: any) => handleFilter(e, 'warehouse')}
                  isDisabled={displayLoader}
                />
              )}
              <DatePicker
                value={date}
                className={clsx('calendar react-datepicker form-control me-3 w-auto')}
                clearIcon={null}
                calendarIcon={null}
                disabled={displayLoader}
                closeCalendar
                defaultView={'month'}
                format={'MM yyyy'}
                isOpen={isCalenderOpen}
                maxDate={moment().toDate()}
                onClickMonth={(e: any) => {
                  setIsCalenderOpen(true)
                  setDate(e)
                }}
                monthAriaLabel={'Month'}
                monthPlaceholder='MM'
                yearAriaLabel={'Year'}
                yearPlaceholder={'YYYY'}
                view={'year'}
              />
              <AsyncPaginate
                loadOptions={fetchAchieved}
                isSearchable
                isDisabled={displayLoader}
                placeholder='All Target'
                onChange={(e) => handleFilter(e, 'achieved')}
                className='react-select-container my-1 w-200px me-3'
                classNamePrefix='react-select'
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
              {moment(date).format('MMM') !== moment().format('MMM') && (
                <AsyncPaginate
                  loadOptions={fetchStatus}
                  isSearchable
                  placeholder='All Status'
                  onChange={(e) => handleFilter(e, 'status')}
                  isDisabled={displayLoader}
                  className='react-select-container my-1 w-200px me-3'
                  classNamePrefix='react-select'
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
              )}
            </div>
          </div>
        </div>
        <div className='card-body py-3'>
          <div
            className='table-responsive expandable'
            style={{transition: '.5s linear all', borderCollapse: 'collapse'}}
            id='expandTable'
          >
            {displayLoader ? (
              <FetchingRecords />
            ) : targets.length === 0 ? (
              <NoRecords />
            ) : (
              <table className='table table-row-bordered align-middle gs-0 gy-2 mb-0'>
                <thead>
                  <tr className='text-muted fw-500'>
                    <th className='min-w-120px w-120px'>SR Name</th>
                    <th className='w-160px min-w-160px'>Revenue Target </th>
                    <th className='w-160px min-w-160px'>Revenue Incentive</th>
                    <th className='w-160px min-w-160px'>Fixed Incentive</th>
                    <th className='w-160px min-w-160px'>Bonus Incentive</th>
                    <th className='w-130px min-w-150px'>Incentive Payable</th>
                    {checkUserType(['wm']) &&
                    moment(date).format('MMM') === moment().format('MMM') ? null : (
                      <th className='w-250px min-w-250px'>Status</th>
                    )}
                    <th className='w-50px min-w-50px'></th>
                  </tr>
                </thead>
                <tbody>
                  {targets.map((target: any) => {
                    return (
                      <>
                        <tr>
                          <td>{target.sr_name}</td>
                          <td>
                            {isEmpty(target.revenue_target_defined)
                              ? '-'
                              : numberFormatWithCode(target.revenue_target_defined).displayFormat}
                          </td>
                          <td>
                            {target.kpi_incentive_component_target_rel &&
                              numberFormatWithCode(
                                target.kpi_incentive_component_target_rel.revenue_incentive_achieved
                              ).displayFormat}
                          </td>
                          <td>
                            {target.kpi_incentive_component_target_rel &&
                              numberFormatWithCode(
                                target.kpi_incentive_component_target_rel.fixed_incentive_achieved
                              ).displayFormat}
                          </td>
                          <td>
                            {target.kpi_incentive_component_target_rel &&
                              numberFormatWithCode(
                                target.kpi_incentive_component_target_rel.bonus_incentive_achieved
                              ).displayFormat}
                          </td>
                          <td
                            className={`${
                              target.target_commission_payable > 0 ? 'text-success' : 'text-danger'
                            }`}
                          >
                            {numberFormatWithCode(target.target_commission_payable).displayFormat}
                          </td>
                          {checkUserType(['wm']) &&
                          moment(date).format('MMM') === moment().format('MMM') ? null : (
                            <td>
                              <div className='d-flex text-center'>
                                {moment(date).format('MMM') === moment().format('MMM') &&
                                !target.is_raised_disciplinary_case &&
                                !target.is_comm_paid ? (
                                  checkUserType(['am']) ? (
                                    <button
                                      className='btn btn-primary me-3'
                                      data-bs-toggle='modal'
                                      data-bs-target='#ea_modal_Flag'
                                      onClick={() => {
                                        setFlagDetails({
                                          id: target.id,
                                          srName: target.sr_name,
                                        })
                                      }}
                                    >
                                      Raise a Flag
                                    </button>
                                  ) : target.is_raised_disciplinary_case ? (
                                    <span
                                      onClick={() => handleFlagged(target)}
                                      className='btn btn-secondary me-3'
                                    >
                                      Flagged
                                    </span>
                                  ) : (
                                    '-'
                                  )
                                ) : (
                                  <>
                                    {!target.is_comm_paid &&
                                    !target.is_raised_disciplinary_case &&
                                    checkUserType(['am']) ? (
                                      <>
                                        {target.target_commission_payable > 0 && (
                                          <button
                                            className='btn btn-primary me-3'
                                            onClick={() => {
                                              targetPaid(target.id).then()
                                            }}
                                          >
                                            Mark as Paid
                                          </button>
                                        )}

                                        <button
                                          className='btn btn-primary me-3'
                                          data-bs-toggle='modal'
                                          data-bs-target='#ea_modal_Flag'
                                          onClick={() => {
                                            setFlagDetails({
                                              id: target.id,
                                              srName: target.sr_name,
                                            })
                                          }}
                                        >
                                          Raise a Flag
                                        </button>
                                      </>
                                    ) : target.is_comm_paid ||
                                      target.is_raised_disciplinary_case ? (
                                      ''
                                    ) : (
                                      '-'
                                    )}

                                    {target.is_comm_paid ? (
                                      <span className='btn btn-success me-3'>Paid</span>
                                    ) : target.is_raised_disciplinary_case ? (
                                      <span
                                        onClick={() => handleFlagged(target)}
                                        className='btn btn-secondary me-3'
                                      >
                                        Flagged
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </div>
                            </td>
                          )}
                          <td
                            className='cursor-pointer'
                            onClick={() => fetchSingleDetails(target.id)}
                          >
                            <div id='kt_drawer_chat_toggle'>
                              <i className='fa fa-info-circle' aria-hidden='true'></i>
                            </div>
                          </td>
                        </tr>
                      </>
                    )
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
      <CustomPaginate
        data={targets}
        selected={selected}
        setSelected={setSelected}
        setCurrentOffset={setCurrentOffSet}
        totalRecords={count}
        limit={limit}
        isDisabled={displayLoader}
      />

      <EaModal
        id='ea_modal_Flag'
        modalTitle={'Mark a Flag'}
        onSubmit={markAsFlag}
        closeAuto='closeAddEaModalFlag'
        cancel={isClear}
        actionBtnName={buttonHandler}
        btnDisabled={buttonHandler !== 'Submit' ? true : false}
      >
        <div className='alert alert-warning' role='alert'>
          If any disciplinary case(s) was registered against the Sales Rep "
          {raiseFlagDetails.srName}" in the month of {dateFormat(date, 'MMMM yyyy').displayDate},
          please mark the flag details below!
        </div>
        <div className='mb-3'>
          <label htmlFor=''>Reason</label>
          <AsyncPaginate
            loadOptions={fetchReason}
            onChange={(e) => {
              setReason(e)
              setValidation('')
            }}
            isSearchable
            placeholder='Select'
            value={reason}
            className={`react-select-container ${validation}`}
            classNamePrefix='react-select'
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: '#0c78a4',
              },
            })}
          />
        </div>

        {reason && reason.label && reason.label === 'Other' && (
          <div className='mb-3'>
            <label htmlFor=''>Comment</label>
            <textarea
              className={`form-control ${validationComment}`}
              rows={4}
              placeholder='Type here…'
              onChange={(e) => {
                setComment(e.target.value)
                setValidationComment('')
              }}
              value={comment}
              name='note'
            ></textarea>
          </div>
        )}
      </EaModal>

      <Modal show={isFlagged} centered>
        <Modal.Header
          closeButton
          onClick={() => {
            setFlagged(false)
          }}
        >
          <Modal.Title>Flagged</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='p-5'>
            <div className='mb-5'>
              <label className='text-muted mb-1'>Month</label>
              <div>
                {raiseFlagDetailsModal &&
                  dateFormat(raiseFlagDetailsModal.raised_disciplinary_date, 'MMMM yyyy')
                    .displayDate}
              </div>
            </div>
            <div className='mb-5'>
              <label className='text-muted mb-1'>Reason</label>
              <div>{raiseFlagDetailsModal && raiseFlagDetailsModal.reason_name}</div>
            </div>
            <div className='mb-5'>
              <label className='text-muted mb-1'>Comment</label>
              <div>{raiseFlagDetailsModal && raiseFlagDetailsModal.reason_note}</div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{
            alignItems: 'center',
          }}
        >
          <button
            onClick={() => {
              setFlagged(false)
            }}
            className='btn btn-primary'
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>

      <>
        <div
          id='kt_drawer_chat'
          data-kt-drawer='true'
          data-kt-drawer-name='chat'
          data-kt-drawer-activate='true'
          data-kt-drawer-overlay='true'
          data-kt-drawer-width="{default:'300px', 'lg': '810px'}"
          data-kt-drawer-direction='end'
          data-kt-drawer-toggle='#kt_drawer_chat_toggle'
          data-kt-drawer-close='#kt_drawer_chat_close'
        >
          <div className='card w-100 drawer-on-grey' id='kt_drawer_chat_messenger'>
            <div className='card-header pe-5 bg-white' id='kt_drawer_chat_messenger_header'>
              <div className='card-title d-flex justify-content-between'>
                <div className='d-flex justify-content-center flex-column me-3'>
                  <a
                    href='javascript:void(0)'
                    className='fs-6 fw-bold text-gray-900 text-hover-primary lh-1'
                  >
                    Incentive Bifurcation
                  </a>
                </div>
              </div>
              <div className='card-toolbar'>
                <ExportButtonDetails />
                {/* <div className='btn btn-sm btn-icon btn-active-light-primary'>
                  <KTSVG path='/media/icons/duotune/files/fil021.svg' className='svg-icon-2' />
                </div> */}
                <div
                  className='btn btn-sm btn-icon btn-active-light-primary'
                  id='kt_drawer_chat_close'
                >
                  <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
                </div>
              </div>
            </div>
            {isFetching ? (
              <FetchingRecords />
            ) : (
              singleDetails &&
              singleDetails.id && (
                <>
                  <div className='card-body bg-white mb-2'>
                    <h3 className='card-title  justify-content-between align-items-start d-flex my-0 me-0 border-bottom pb-5 mb-5'>
                      <div>
                        <span className='card-label fw-bold fs-6 mb-1'>
                          {singleDetails.sr_user_name}
                        </span>
                        <div className='font-14 fw-normal'>{singleDetails.sales_team_name}</div>
                      </div>

                      <div>
                        <span className='card-label fw-bold fs-6 mb-1'>Revenue Target</span>
                        <div className='font-14 fw-normal'>
                          {singleDetails.revenue_target_defined && (
                            <>
                              {singleDetails.revenue_target_defined &&
                                numberFormatWithCode(singleDetails.revenue_target_defined)
                                  .displayFormat}{' '}
                            </>
                          )}
                        </div>
                      </div>

                      <div>
                        <span className='card-label fw-bold fs-6 mb-1'>Revenue Month</span>
                        <div className='font-14 fw-normal'>
                          {singleDetails &&
                            singleDetails.month &&
                            `${dateFormat(singleDetails.month, 'MMMM yyyy').displayDate}`}
                        </div>
                      </div>
                    </h3>
                    <div className='row justify-content-between'>
                      <div className='col-md-4 mb-4'>
                        <div className='w-200px'>
                          <p className='mb-1 font-13 text-primary fw-600'>Revenue Incentive</p>
                          <p className='mb-2 font-15'>
                            {
                              numberFormatWithCode(
                                singleDetails.kpi_incentive_component_target_rel
                                  .revenue_incentive_achieved
                              ).displayFormat
                            }
                          </p>
                          <div className='progress h-6px w-100'>
                            <div
                              className={`progress-bar bg-${
                                parseInt(
                                  singleDetails.kpi_incentive_component_target_rel
                                    .revenue_incentive_percentage_achieved
                                ) >= 80
                                  ? 'success'
                                  : 'warning'
                              } rounded`}
                              role='progressbar'
                              style={{
                                width: `${parseInt(
                                  singleDetails.kpi_incentive_component_target_rel
                                    .revenue_incentive_percentage_achieved
                                )}%`,
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-4 mb-4'>
                        <div className='w-200px'>
                          <p className='mb-1 font-13 text-primary fw-600'>Fixed Incentive</p>
                          <p className='mb-2 font-15'>
                            {
                              numberFormatWithCode(
                                singleDetails.kpi_incentive_component_target_rel
                                  .fixed_incentive_achieved
                              ).displayFormat
                            }
                          </p>
                          <div className='progress h-6px w-100'>
                            <div
                              className='progress-bar bg-success rounded'
                              role='progressbar'
                              style={{
                                width: `${parseInt(
                                  singleDetails.kpi_incentive_component_target_rel
                                    .fixed_incentive_percentage_achieved
                                )}%`,
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-4 mb-4'>
                        <p className='mb-1 font-13 text-primary fw-600'>Bonus Incentive</p>
                        <p className='mb-2 font-15'>
                          {
                            numberFormatWithCode(
                              singleDetails.kpi_incentive_component_target_rel
                                .bonus_incentive_achieved
                            ).displayFormat
                          }
                        </p>
                      </div>
                      <div className='col-md-12'>
                        <p className='font-15 fw-600 text-success mb-0'>
                          {
                            numberFormatWithCode(singleDetails.target_commission_payable)
                              .displayFormat
                          }{' '}
                          Commission Payable
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='card-body bg-white mb-2'>
                    <h3 className='card-title  m-0 pb-2 fw-bold fs-6'>Revenue Incentive</h3>
                    <div className='d-flex justify-content-between align-items-center'>
                      <p className='font-14 fw-500 mb-2'>
                        Target -{' '}
                        {numberFormatWithCode(singleDetails.revenue_target_achieved).displayFormat}{' '}
                        (
                        {
                          numberFormat(singleDetails.revenue_target_percentage_achieved, true)
                            .displayFormat
                        }
                        %)
                      </p>
                      <p className='font-14 fw-500 mb-2 text-muted'>
                        {numberFormatWithCode(singleDetails.revenue_target_defined).displayFormat}
                      </p>
                    </div>
                    <div className='progress h-8px w-100 mb-2'>
                      <div
                        className={`progress-bar rounded bg-${
                          parseInt(singleDetails.revenue_target_percentage_achieved) >= 80
                            ? 'success'
                            : 'warning'
                        } rounded`}
                        role='progressbar'
                        style={{
                          width: `${parseInt(singleDetails.revenue_target_percentage_achieved)}%`,
                        }}
                      ></div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center'>
                      <p className='font-14 fw-500 mb-0'>
                        Incentive -{' '}
                        {
                          numberFormatWithCode(
                            singleDetails.kpi_incentive_component_target_rel
                              .revenue_incentive_achieved
                          ).displayFormat
                        }
                      </p>
                      <p className='font-14 fw-500 mb-0 text-muted'>
                        {
                          numberFormatWithCode(
                            singleDetails.kpi_incentive_component_target_rel
                              .revenue_incentive_defined
                          ).displayFormat
                        }
                      </p>
                    </div>
                  </div>
                  <div className='card-body bg-white mb-2'>
                    <div className='d-flex justify-content-between align-items-center'>
                      <h3 className='card-title  m-0 pb-2 fw-bold fs-6'>Fixed Incentive</h3>
                    </div>
                    <div className='d-flex justify-content-between align-items-center'>
                      <p className='font-14 fw-500 mb-2'>
                        Incentive -{' '}
                        {
                          numberFormatWithCode(
                            singleDetails.kpi_incentive_component_target_rel
                              .fixed_incentive_achieved
                          ).displayFormat
                        }{' '}
                        (
                        {
                          numberFormat(
                            singleDetails.kpi_incentive_component_target_rel
                              .fixed_incentive_percentage_achieved,
                            true
                          ).displayFormat
                        }
                        %)
                      </p>
                      <p className='font-14 fw-500 mb-2 text-muted'>
                        {
                          numberFormatWithCode(
                            singleDetails.kpi_incentive_component_target_rel.fixed_incentive_defined
                          ).displayFormat
                        }
                      </p>
                    </div>
                    <div className='border-bottom pb-4 mb-4'>
                      <div className='progress h-8px w-100'>
                        <div
                          className='progress-bar bg-success rounded'
                          role='progressbar'
                          style={{
                            width: `${parseInt(
                              singleDetails.kpi_incentive_component_target_rel
                                .fixed_incentive_percentage_achieved
                            )}%`,
                          }}
                        ></div>
                      </div>
                    </div>
                    <div className='table-responsive'>
                      <table className='table table-hover table-row-bordered table-row-gray-100 align-baseline gy-3'>
                        <thead>
                          <tr className='text-muted fw-500'>
                            <th className=''>KPIs</th>
                            <th className=''>Commission Dist.(%)</th>
                            <th className=''>Target</th>
                            <th className=''>Achieved</th>
                            <th className=''>Incentive Amt.</th>
                          </tr>
                        </thead>
                        <tbody>
                          {singleDetails.target_kpis.length > 0 &&
                            singleDetails.target_kpis.map((item: any) => {
                              return (
                                <tr className='cursor-pointer'>
                                  <td>{item.kpi_parameter_display_name}</td>
                                  <td>{item.kpi_target_comm_divi_value}%</td>
                                  <td>
                                    {numberFormat(item.kpi_target_defined).displayFormat}
                                    {item.kpi_parameter_measurement_unit === 'percentage'
                                      ? '%'
                                      : ''}{' '}
                                    {isAchieved(item.kpi_parameter_name)}
                                  </td>
                                  <td>
                                    {
                                      numberFormat(
                                        item.kpi_target_achieved,
                                        item.kpi_parameter_measurement_unit === 'percentage'
                                          ? true
                                          : false
                                      ).displayFormat
                                    }
                                    {item.kpi_parameter_measurement_unit === 'percentage'
                                      ? '%'
                                      : ''}{' '}
                                    {isAchieved(item.kpi_parameter_name)}
                                  </td>
                                  <td>
                                    {numberFormatWithCode(item.kpi_incentive_amount).displayFormat}
                                  </td>
                                </tr>
                              )
                            })}
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>
                              <span className='fw-500'>Total</span>
                            </td>
                            <td>
                              <span className='fw-500'>
                                {isEmpty(
                                  singleDetails.kpi_incentive_component_target_rel
                                    .fixed_incentive_achieved
                                )
                                  ? '-'
                                  : numberFormatWithCode(
                                      singleDetails.kpi_incentive_component_target_rel
                                        .fixed_incentive_achieved
                                    ).displayFormat}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className='card-body bg-white'>
                    <div
                      className='d-flex justify-content-between align-baseline border-bottom pb-4 mb-4 cursor-pointer'
                      data-bs-toggle='collapse'
                      data-bs-target='.incentive-collapse'
                      aria-controls='incentiveCollapse'
                    >
                      <div>
                        <h3 className='card-title  m-0 pb-1 fw-bold fs-6'>
                          Bonus Incentive (
                          {
                            singleDetails.kpi_incentive_component_target_rel
                              .bonus_incentive_perc_defined
                          }
                          % of revenue achieved)
                        </h3>
                        <p className='font-14 fw-500 mb-0'>
                          Incentive -{' '}
                          {
                            numberFormatWithCode(
                              singleDetails.kpi_incentive_component_target_rel
                                .bonus_incentive_achieved === 0
                                ? singleDetails.kpi_incentive_component_target_rel
                                    .bonus_incentive_defined
                                : singleDetails.kpi_incentive_component_target_rel
                                    .bonus_incentive_achieved
                            ).displayFormat
                          }
                        </p>
                      </div>
                      <div>
                        <img src='/media/icons/ic_up.svg' />
                      </div>
                    </div>
                    <div
                      className='table-responsive collapse incentive-collapse collapsible show'
                      id='incentiveCollapse'
                    >
                      <table className='table table-hover table-row-bordered table-row-gray-100 align-baseline gy-3'>
                        <thead>
                          <tr className='text-muted fw-500'>
                            <th className=''>Eligible for bonus incentive if,</th>
                            <th className=''>Target</th>
                            <th className=''>Achieved</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className='cursor-pointer'>
                            <td>Revenue</td>
                            <td>
                              {'>'}{' '}
                              {
                                numberFormatWithCode(
                                  singleDetails.kpi_incentive_component_target_rel
                                    .revenue_incentive_atleast_defined
                                ).displayFormat
                              }
                            </td>
                            <td>
                              {
                                numberFormatWithCode(singleDetails.revenue_target_achieved)
                                  .displayFormat
                              }
                            </td>
                          </tr>
                          <tr className='cursor-pointer'>
                            <td>Discount</td>
                            <td>
                              {'<'}{' '}
                              {
                                numberFormat(
                                  singleDetails.kpi_incentive_component_target_rel
                                    .discount_prec_atmost_defined,
                                  true
                                ).displayFormat
                              }
                              %
                            </td>
                            <td>
                              {
                                numberFormat(
                                  singleDetails.kpi_incentive_component_target_rel
                                    .discount_prec_achieved
                                ).displayFormat
                              }
                              %
                            </td>
                          </tr>
                          <tr className='cursor-pointer'>
                            <td>Achievement of Fixed Incentive</td>
                            <td>
                              {'>'}{' '}
                              {
                                numberFormat(
                                  singleDetails.kpi_incentive_component_target_rel
                                    .achieved_perc_atleast_defined,
                                  true
                                ).displayFormat
                              }
                              %
                            </td>
                            <td>
                              {
                                numberFormat(
                                  singleDetails.kpi_incentive_component_target_rel
                                    .fixed_perc_achieved
                                ).displayFormat
                              }
                              %
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              )
            )}
          </div>
        </div>
      </>
    </>
  )
}

export default Targets
